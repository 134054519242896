import { TNumberRange, TNumberRangeFunction } from './use-pagination.types';

/**
 * Creates a new number range
 */
export function createNumberRange(
    rangeStart: number,
    rangeEnd: number,
): TNumberRange {
    if (rangeStart > rangeEnd) {
        throw new Error('rangeStart cannot be greater than rangeEnd');
    }

    return function range<T>(function_: TNumberRangeFunction<T>): T {
        return function_(rangeStart, rangeEnd);
    };
}

/**
 * Creates a range array (an array of consecutive numbers) from the given range
 */
export function createArrayFromRange(range: TNumberRange): number[] {
    return range((rangeStart, rangeEnd) => {
        const array = [];

        for (let page = rangeStart; page <= rangeEnd; page++) {
            array.push(page);
        }

        return array;
    });
}

/**
 * Ensures the given number is within the given range
 */
export function ensureNumberIsWithinRange(range: TNumberRange) {
    return (numberToPlace: number): number => {
        return range((rangeStart, rangeEnd) => {
            if (numberToPlace < rangeStart) {
                return rangeStart;
            }

            if (numberToPlace > rangeEnd) {
                return rangeEnd;
            }

            return numberToPlace;
        });
    };
}

/**
 * Ensures the given range array (an array of consective numbers) is within the given range
 */
export function ensureRangeArrayIsWithinRange(range: TNumberRange) {
    return (numberArray: number[]): number[] => {
        return range((rangeStart, rangeEnd) => {
            if (!numberArray || numberArray.length <= 0) {
                throw new Error('numberArray must be an array of length > 0');
            }

            const arrayLengthLessOne = numberArray.length - 1;
            const firstPage = numberArray[0];
            const lastPage = numberArray[arrayLengthLessOne];

            if (firstPage < 1) {
                const newRange = createNumberRange(
                    rangeStart,
                    rangeStart + arrayLengthLessOne,
                );
                return createArrayFromRange(newRange);
            }

            if (lastPage > rangeEnd) {
                // Create an array that ends in rangeEnd, and is of length arrayLength
                const newRange = createNumberRange(
                    rangeEnd - arrayLengthLessOne,
                    rangeEnd,
                );
                return createArrayFromRange(newRange);
            }

            return numberArray;
        });
    };
}
