import { createContext, useContext } from 'react';

import { TUserContext } from '.';

export const initialAuth = {
    tokenType: '',
    accessToken: '',
    expires: 0,
    expiresIn: 0,
    header: '',
};

const initialContext = {
    auth: initialAuth,
    setAuth: () => initialAuth,
    serviceProviders: [],
    setServiceProviders: () => {},
    logOut: () => {},
};

export const UserContext = createContext<TUserContext>(initialContext);
export const useUser = (): TUserContext => useContext(UserContext);
