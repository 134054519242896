import { useMutation, useQuery } from 'react-query';
import * as Sentry from '@sentry/nextjs';

import {
    getAllocatedSimConfig,
    getAllocatedSimDetail,
    getSimRegistrationStatus,
    getSimRegistrationConfig,
    updateAllocatedSimDetail,
    updateAllocatedSimConfig,
    updateSimRegistrationConfig,
    suspendSim,
    unsuspendSim,
    getSimInboundConfig,
    updateSimInboundConfig,
    terminateSim,
} from '../../../api';
import { useUser } from '../../../contexts';
import {
    TAllocatedSimConfig,
    TAllocatedSimDetail,
    TApiInstanceResponse,
    TSimRegistrationStatus,
    TSimRegistrationConfig,
    TSuccessApiInstanceResponse,
    TSuspendedSim,
    TUnsuspendedSim,
    TSimInboundConfig,
    TModifiedSim,
} from '../../../types';
import { isErrorResponse } from '../../../utils';
import { checkRequiredParams } from '../../../utils/checkRequiredParams';
import {
    TUseMutations,
    TBaseParams,
    TUseQueries,
    TUseQueriesOptions,
} from './allocated-sim.types';

const onError = Sentry.captureException;

const queryOptions = {
    onError,
};

export const useQueries = ({
    spid,
    iccid,
}: TUseQueriesOptions): TUseQueries => {
    const { auth } = useUser();

    const simDetailQuery = useQuery<
        TApiInstanceResponse<TAllocatedSimDetail>,
        string[]
    >(
        ['simDetail', { spid, iccid }],
        () => {
            checkRequiredParams(spid, iccid);
            return getAllocatedSimDetail(
                auth.header,
                spid as string,
                iccid as string,
            );
        },
        queryOptions,
    );

    const simConfigQuery = useQuery<
        TApiInstanceResponse<TAllocatedSimConfig>,
        string[]
    >(
        ['simConfig', { spid, iccid }],
        () => {
            checkRequiredParams(spid, iccid);
            return getAllocatedSimConfig(
                auth.header,
                spid as string,
                iccid as string,
            );
        },
        queryOptions,
    );

    const simRegistrationStatusQuery = useQuery<
        TApiInstanceResponse<TSimRegistrationStatus>,
        string[]
    >(
        ['simRegistrationStatus', { spid, iccid }],
        () => {
            checkRequiredParams(spid, iccid);
            return getSimRegistrationStatus(
                auth.header,
                spid as string,
                iccid as string,
            );
        },
        queryOptions,
    );

    const simRegistrationConfigQuery = useQuery<
        TApiInstanceResponse<TSimRegistrationConfig>,
        string[]
    >(
        ['simRegistrationConfig', { spid, iccid }],
        () => {
            checkRequiredParams(spid, iccid);
            return getSimRegistrationConfig(
                auth.header,
                spid as string,
                iccid as string,
            );
        },
        queryOptions,
    );

    const simInboundConfigQuery = useQuery<
        TApiInstanceResponse<TSimInboundConfig>,
        string[]
    >(
        ['simInboundConfig', { spid, iccid }],
        () => {
            checkRequiredParams(spid, iccid);
            return getSimInboundConfig(
                auth.header,
                spid as string,
                iccid as string,
            );
        },
        queryOptions,
    );

    return {
        simDetailQuery,
        simConfigQuery,
        simRegistrationStatusQuery,
        simRegistrationConfigQuery,
        simInboundConfigQuery,
    };
};

export const useMutations = (): TUseMutations => {
    const { auth } = useUser();

    const simDetailMutation = useMutation<
        TSuccessApiInstanceResponse<TAllocatedSimDetail>,
        string[],
        { updatedSimDetail: Partial<TAllocatedSimDetail> } & TBaseParams
    >(async ({ spid, iccid, updatedSimDetail }) => {
        checkRequiredParams(spid, iccid);

        const res = await updateAllocatedSimDetail(
            auth.header,
            spid as string,
            iccid as string,
            updatedSimDetail,
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const simConfigMutation = useMutation<
        TSuccessApiInstanceResponse<TAllocatedSimConfig>,
        string[],
        { updatedSimConfig: Partial<TAllocatedSimConfig> } & TBaseParams
    >(async ({ spid, iccid, updatedSimConfig }) => {
        checkRequiredParams(spid, iccid);

        const res = await updateAllocatedSimConfig(
            auth.header,
            spid as string,
            iccid as string,
            updatedSimConfig,
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const simRegistrationConfigMutation = useMutation<
        TSuccessApiInstanceResponse<TSimRegistrationConfig>,
        string[],
        {
            updatedSimRegistrationConfig: Partial<TSimRegistrationConfig>;
        } & TBaseParams
    >(async ({ spid, iccid, updatedSimRegistrationConfig }) => {
        checkRequiredParams(spid, iccid);

        const res = await updateSimRegistrationConfig(
            auth.header,
            spid as string,
            iccid as string,
            { ...updatedSimRegistrationConfig, sip_type: 'registration' },
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const simInboundConfigMutation = useMutation<
        TSuccessApiInstanceResponse<TSimInboundConfig>,
        string[],
        { updatedSimInboundConfig: Partial<TSimInboundConfig> } & TBaseParams
    >(async ({ spid, iccid, updatedSimInboundConfig }) => {
        checkRequiredParams(spid, iccid);

        const res = await updateSimInboundConfig(
            auth.header,
            spid as string,
            iccid as string,
            updatedSimInboundConfig,
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const suspendSimMutation = useMutation<
        TSuccessApiInstanceResponse<TSuspendedSim>,
        string[],
        TBaseParams
    >(async ({ spid, iccid }) => {
        checkRequiredParams(spid, iccid);

        const res = await suspendSim(
            auth.header,
            spid as string,
            iccid as string,
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const unsuspendSimMutation = useMutation<
        TSuccessApiInstanceResponse<TUnsuspendedSim>,
        string[],
        TBaseParams
    >(async ({ spid, iccid }) => {
        checkRequiredParams(spid, iccid);

        const res = await unsuspendSim(
            auth.header,
            spid as string,
            iccid as string,
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const terminateSimMutation = useMutation<
        TSuccessApiInstanceResponse<TModifiedSim>,
        string[],
        TBaseParams
    >(async ({ spid, iccid }) => {
        checkRequiredParams(spid, iccid);

        const res = await terminateSim(
            auth.header,
            spid as string,
            iccid as string,
        );

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    return {
        simConfigMutation,
        simDetailMutation,
        simRegistrationConfigMutation,
        simInboundConfigMutation,
        suspendSimMutation,
        unsuspendSimMutation,
        terminateSimMutation,
    };
};
