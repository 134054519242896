import { Fragment, ReactElement } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';

import { TAlertProps } from './alert.types';

export const Alert = ({
    heading,
    children,
    Buttons,
    Icon,
    show,
    onClose,
    initialFocus,
    iconBackgroundClassName,
}: TAlertProps): ReactElement => {
    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={initialFocus}
                open={show}
                onClose={onClose}
            >
                <div className="flex items-start sm:items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all my-8 max-w-lg w-full sm:p-6">
                            <div className="flex flex-col items-start">
                                <div className="flex items-center">
                                    <div
                                        className={clsx(
                                            'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
                                            iconBackgroundClassName,
                                        )}
                                    >
                                        <Icon />
                                    </div>
                                    <div className="w-full mt-0 ml-4 text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        >
                                            {heading}
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <div className="mt-2">{children}</div>
                            </div>
                            <div className="mt-5 sm:mt-4">
                                <Buttons />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
