import React, { ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastProvider } from 'react-toast-notifications';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AppProvider, UserProvider } from '../../../contexts';
import { Notification, NotificationContainer } from '../../';
import { TProvidersProps } from './providers.types';

export const Providers = ({ children }: TProvidersProps): ReactElement => {
    const queryClient = new QueryClient();

    return (
        <ToastProvider
            components={{
                // This ignore is because I'm adding additional props to the Notification component
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                Toast: Notification,
                ToastContainer: NotificationContainer,
            }}
            transitionDuration={0}
            autoDismiss
            autoDismissTimeout={8000}
        >
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <AppProvider>
                    <UserProvider>{children}</UserProvider>
                </AppProvider>
            </QueryClientProvider>
        </ToastProvider>
    );
};
