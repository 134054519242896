import { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Input, InputGroup, Label } from '@joshfarrant/tailwind-ui-react';
import Link from 'next/link';
import Head from 'next/head';

import { PrimaryButton, Loading } from '../../atoms';
import { listEntitlements, login } from '../../../api';
import { TUserAuth, useUser } from '../../../contexts';
import { isErrorResponse } from '../../../utils';
import { useLocalStorage } from '../../../hooks';

export const Login = (): ReactElement => {
    const router = useRouter();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const { setAuth, setServiceProviders } = useUser();
    const [rememberMe, setRememberMe] = useLocalStorage<boolean>(
        'ap-remember-me',
        false,
    );
    const [
        localStorageAuth,
        setLocalStorageAuth,
    ] = useLocalStorage<TUserAuth | null>('ap-auth', null);

    const getServiceProvidersAndRedirect = async (
        auth: TUserAuth,
    ): Promise<void> => {
        const entitlementsResponse = await listEntitlements(auth.header);

        if (isErrorResponse(entitlementsResponse)) {
            setErrors(entitlementsResponse.errors);
            return;
        }

        const {
            service_providers: serviceProviders,
        } = entitlementsResponse.data;

        const normalizedServiceProviders = serviceProviders.map(
            ({ spid, name }) => ({
                spid: spid.replace(/^SP/, ''),
                name,
            }),
        );

        setServiceProviders(normalizedServiceProviders);

        const firstServiceProvider = normalizedServiceProviders[0];

        const redirectUrl =
            (router.query.from as string) ||
            `/${firstServiceProvider.spid}/sims/allocated`;

        router.push(redirectUrl);
    };

    const performLogin = async (): Promise<void> => {
        const loginResponse = await login(email, password);

        if (isErrorResponse(loginResponse)) {
            setErrors(loginResponse.errors);
            return;
        }

        const { data } = loginResponse;
        const auth = setAuth({
            tokenType: data.token_type,
            accessToken: data.access_token,
            expires: data.expires,
            expiresIn: data.expires_in,
        });

        setLocalStorageAuth(rememberMe ? auth : null);

        await getServiceProvidersAndRedirect(auth);
    };

    const checkForStoredAuth = async (): Promise<void> => {
        if (localStorageAuth) {
            const now = Math.floor(Date.now() / 1000);
            const isValidAuth =
                localStorageAuth.header && localStorageAuth.expires > now;

            if (isValidAuth) {
                setAuth(localStorageAuth);
                getServiceProvidersAndRedirect(localStorageAuth);
            } else {
                setLocalStorageAuth(null);
            }
        }
    };

    useEffect(() => {
        checkForStoredAuth();
    }, []);

    const hasResetPassword = Boolean(router.query.passwordReset);

    if (localStorageAuth && errors.length === 0) {
        return <Loading />;
    }

    return (
        <>
            <Head>
                <title>Login : ApaloSIM</title>
            </Head>
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-24 w-auto"
                        src="/logo@2x.png"
                        alt="Apalo"
                    />
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        {hasResetPassword && (
                            <p className="mb-6">
                                Your password has been successfully changed.
                                Please continue to log in.
                            </p>
                        )}
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                performLogin();
                            }}
                        >
                            <InputGroup
                                id="email"
                                renderInput={inputProps => (
                                    <Input
                                        {...inputProps}
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        required
                                        value={email}
                                        onChange={e => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                )}
                                renderLabel={labelProps => (
                                    <Label {...labelProps}>Email address</Label>
                                )}
                            />

                            <InputGroup
                                className="mt-6"
                                id="password"
                                renderInput={inputProps => (
                                    <Input
                                        {...inputProps}
                                        type="password"
                                        name="password"
                                        autoComplete="current-password"
                                        required
                                        value={password}
                                        onChange={e => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                )}
                                renderLabel={labelProps => (
                                    <Label {...labelProps}>Password</Label>
                                )}
                            />

                            {errors.length > 0 && (
                                <div className="mt-3 text-sm text-danger-600">
                                    {errors.join(', ')}
                                </div>
                            )}

                            <div className="flex items-center justify-between mt-6">
                                <div className="flex items-center">
                                    <input
                                        id="remember_me"
                                        name="remember_me"
                                        type="checkbox"
                                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                        checked={rememberMe}
                                        onChange={e =>
                                            setRememberMe(e.target.checked)
                                        }
                                    />
                                    <label
                                        htmlFor="remember_me"
                                        className="ml-2 block text-sm text-gray-900"
                                    >
                                        Remember me
                                    </label>
                                </div>

                                <div className="text-sm">
                                    <Link href="/forgot-password">
                                        <a className="font-medium text-blue-600 hover:text-blue-500">
                                            Forgot your password?
                                        </a>
                                    </Link>
                                </div>
                            </div>

                            <PrimaryButton
                                className="mt-6"
                                type="submit"
                                fullWidth
                            >
                                Sign in
                            </PrimaryButton>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
