import { ReactElement } from 'react';
import clsx from 'clsx';
import { HeaderGroup, usePagination, useSortBy, useTable } from 'react-table';

import { Pagination } from '../../';
import { TCustomColumnProps, TTableProps } from './table.types';

export const Table = <T extends Record<string, unknown>>({
    data = [],
    columns = [],
    initialState,
    itemCount,
    pageSize,
    onPaginate = () => {},
    canSort = false,
    onSort = () => {},
}: TTableProps<T>): ReactElement => {
    const totalPages = Math.ceil(itemCount / pageSize);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        state: { pageIndex },
        gotoPage,
    } = useTable<T>(
        {
            columns,
            data,
            initialState,
            manualPagination: true,
            manualSortBy: true,
            pageCount: totalPages,
        },
        useSortBy,
        usePagination,
    );

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table
                            {...getTableProps()}
                            className="min-w-full divide-y divide-gray-200"
                        >
                            <thead className="bg-gray-50">
                                {headerGroups.map(headerGroup => (
                                    // eslint-disable-next-line react/jsx-key
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(
                                            (
                                                column: HeaderGroup<T> &
                                                    TCustomColumnProps,
                                            ) => (
                                                // eslint-disable-next-line react/jsx-key
                                                <th
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps(),
                                                    )}
                                                    className={column.className}
                                                    onClick={() => {
                                                        if (
                                                            !canSort ||
                                                            !column.canSort
                                                        ) {
                                                            return;
                                                        }
                                                        const nextSortIsDesc = !column.isSortedDesc;
                                                        onSort(
                                                            column.id,
                                                            nextSortIsDesc,
                                                        );
                                                        column.toggleSortBy(
                                                            nextSortIsDesc,
                                                            false,
                                                        );
                                                    }}
                                                >
                                                    {column.render('Header', {
                                                        canSort:
                                                            canSort &&
                                                            column.canSort,
                                                        isSorted:
                                                            column.isSorted,
                                                        isSortedDesc:
                                                            column.isSortedDesc,
                                                    })}
                                                </th>
                                            ),
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {(page ?? rows).map((row, i) => {
                                    prepareRow(row);
                                    const isEven = (i + 1) % 2 === 0;
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <tr
                                            {...row.getRowProps()}
                                            className={clsx(
                                                isEven
                                                    ? 'bg-gray-50'
                                                    : 'bg-white',
                                            )}
                                        >
                                            {row.cells.map(cell => (
                                                // eslint-disable-next-line react/jsx-key
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            onPaginate={nextPage => {
                                onPaginate(nextPage);
                                gotoPage(nextPage);
                            }}
                            currentPage={pageIndex}
                            totalItems={itemCount}
                            totalPages={totalPages}
                            pageSize={pageSize}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
