import {
    createContext,
    useContext,
    useRef,
    useState,
    ReactElement,
} from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { InformationCircleIcon } from '@heroicons/react/outline';

import { Alert, WhiteButton } from '../..';
import { TTabProps, TTabViewProps } from './tab-view.types';
import { useRouter } from 'next/router';

const TabContext = createContext('');

export const TabView = ({
    selectedTabId,
    children,
    disabled,
    tabs,
}: TTabViewProps): ReactElement => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const confirmButtonRef = useRef(null);
    const router = useRouter();
    const { spid, iccid } = router.query;

    return (
        <>
            <Alert
                heading="Unsaved Changes"
                onClose={() => {
                    setConfirmModalOpen(false);
                }}
                show={confirmModalOpen}
                initialFocus={confirmButtonRef}
                Buttons={() => (
                    <div className="flex justify-end">
                        <WhiteButton
                            className="mr-4"
                            onClick={() => {
                                setConfirmModalOpen(false);
                            }}
                            ref={confirmButtonRef}
                        >
                            Close
                        </WhiteButton>
                    </div>
                )}
                Icon={() => (
                    <InformationCircleIcon
                        className="h-6 w-6 text-primary-600"
                        aria-hidden="true"
                    />
                )}
                iconBackgroundClassName="bg-primary-100"
            >
                <p className="text-sm text-gray-500">
                    Save or cancel your changes to continue.
                </p>
            </Alert>
            <TabContext.Provider value={selectedTabId}>
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        <div className="w-full">
                            <div className="border-b border-gray-200">
                                <nav
                                    className="-mb-px flex space-x-8"
                                    aria-label="Tabs"
                                >
                                    {tabs.map(({ id, Icon, heading }) => (
                                        <Link
                                            href={`/${spid}/sims/allocated/${iccid}/${id}`}
                                            key={id}
                                            shallow
                                        >
                                            <a
                                                className={clsx(
                                                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm',
                                                    disabled &&
                                                        'cursor-not-allowed',
                                                    id === selectedTabId
                                                        ? disabled
                                                            ? 'border-primary-200 text-gray-400'
                                                            : 'border-primary-500 text-primary-600'
                                                        : disabled
                                                        ? 'border-transparent text-gray-400'
                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                )}
                                            >
                                                <Icon
                                                    className={clsx(
                                                        'hidden sm:block -ml-0.5 mr-2 h-5 w-5',
                                                        id === selectedTabId
                                                            ? disabled
                                                                ? 'text-gray-300'
                                                                : 'text-primary-500'
                                                            : disabled
                                                            ? 'text-gray-300'
                                                            : 'text-gray-400 group-hover:text-gray-500',
                                                    )}
                                                />
                                                <span>{heading}</span>
                                            </a>
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </TabContext.Provider>
        </>
    );
};

export const Tab = ({ id, children }: TTabProps): ReactElement | null => {
    const activeTabIdx = useContext(TabContext);

    if (id !== activeTabIdx) {
        return null;
    }

    return <>{children}</>;
};
