import { ReactElement } from 'react';
import {
    Input,
    InputGroup,
    Label,
    Select,
    Toggle,
} from '@joshfarrant/tailwind-ui-react';
import clsx from 'clsx';
import { Switch } from '@headlessui/react';

import { Setting } from '../../';
import { TMobileConfigurationProps } from './mobile-configuration.types';
import { DATA_BUNDLES, ROAMING } from '../../../constants';
import { TDataBundle, TRoaming } from '../../../types';

export const MobileConfiguration = ({
    simConfig,
    simDetail,
    onConfigChange,
    onDetailChange,
    disabled,
}: TMobileConfigurationProps): ReactElement => {
    const {
        data_bundle,
        enable_adult,
        enable_data,
        enable_prs,
        roaming,
    } = simConfig;

    const { label } = simDetail;

    const toggleWrapperClasses = 'flex flex-row mb-4 items-center';
    const toggleLabelClasses = 'ml-4 text-sm text-gray-500';

    return (
        <div className="mt-8 grid grid-cols-5 gap-y-2 md:gap-x-8 md:gap-y-8">
            <div className="col-span-full mb-8 md:mb-0">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Voice Calls
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    All Mobile Originated (MO) calls will be routed to the SIP
                    service defined.
                </p>
            </div>
            <Setting
                heading="Label"
                description="A label which can be used to help identify and search for your SIM."
            >
                <InputGroup
                    className="mt-5"
                    id="label"
                    renderInput={inputProps => (
                        <Input
                            {...inputProps}
                            value={label ?? ''}
                            onChange={e =>
                                onDetailChange({ label: e.target.value })
                            }
                            disabled={disabled}
                        />
                    )}
                    renderLabel={labelProps => (
                        <Label {...labelProps} hidden>
                            Label
                        </Label>
                    )}
                />
            </Setting>
            <Setting
                heading="Mobile Data"
                description="Please select a Mobile Data bundle for this SIM. Mobile Bundles will be billed at the agreed rate, additional costs may apply for roaming data. We use reasonable efforts to ensure that data usage does not exceed this bundle."
            >
                <div className={clsx(toggleWrapperClasses, 'mt-5')}>
                    <Switch.Group>
                        <Toggle
                            srLabel={`${
                                enable_data ? 'Disable' : 'Enable'
                            } data`}
                            enabled={enable_data}
                            onChange={checked => {
                                onConfigChange({
                                    enable_data: checked,
                                });
                            }}
                            disabled={disabled}
                        />
                        <Switch.Label className={toggleLabelClasses}>
                            Enable Data
                        </Switch.Label>
                    </Switch.Group>
                </div>
                <Select
                    className="w-full mt-5"
                    options={Object.entries(DATA_BUNDLES).map(
                        ([value, label]) => ({
                            value,
                            label,
                        }),
                    )}
                    onChange={(value: unknown) => {
                        onConfigChange({
                            data_bundle: value as TDataBundle,
                        });
                    }}
                    value={data_bundle ?? 'no'}
                    label="Mobile data"
                    labelHidden
                    disabled={disabled || !enable_data}
                    renderSelect={props => (
                        <select
                            id="mobile-data"
                            name="mobile-data"
                            {...props}
                        />
                    )}
                    renderLabel={props => (
                        <label htmlFor="mobile-data" {...props} />
                    )}
                />
            </Setting>
            <Setting
                heading="Roaming"
                description="Please consult your price guide for information on roaming costs. To avoid unexpected billing you can disable roaming or limit it to roaming within the EU."
            >
                <Select
                    className="w-full mt-5"
                    options={Object.entries(ROAMING).map(([value, label]) => ({
                        value,
                        label,
                    }))}
                    value={roaming}
                    onChange={(value: unknown) => {
                        onConfigChange({
                            roaming: value as TRoaming,
                        });
                    }}
                    label="Roaming"
                    labelHidden
                    renderSelect={props => (
                        <select id="roaming" name="roaming" {...props} />
                    )}
                    renderLabel={props => (
                        <label htmlFor="roaming" {...props} />
                    )}
                    disabled={disabled}
                />
            </Setting>
            <Setting
                heading="Content Barring"
                description="By default, access to Premium Rate Services (PRS) and Adult Content Services are barred at the network level, you can enable them as required."
            >
                <div className="flex flex-col mt-5">
                    <div className={toggleWrapperClasses}>
                        <Switch.Group>
                            <Toggle
                                srLabel={`${
                                    enable_adult ? 'Disable' : 'Enable'
                                } adult content`}
                                enabled={enable_adult}
                                onChange={checked =>
                                    onConfigChange({
                                        enable_adult: checked,
                                    })
                                }
                                disabled={disabled}
                            />
                            <Switch.Label className={toggleLabelClasses}>
                                Enable Adult Content Services
                            </Switch.Label>
                        </Switch.Group>
                    </div>
                    <div className={toggleWrapperClasses}>
                        <Switch.Group>
                            <Toggle
                                srLabel={`${
                                    enable_prs ? 'Disable' : 'Enable'
                                } premium-rate services`}
                                enabled={enable_prs}
                                onChange={checked => {
                                    onConfigChange({
                                        enable_prs: checked,
                                    });
                                }}
                                disabled={disabled}
                            />
                            <Switch.Label className={toggleLabelClasses}>
                                Enable Premium Rate Services
                            </Switch.Label>
                        </Switch.Group>
                    </div>
                </div>
            </Setting>
        </div>
    );
};
