import { ReactElement } from 'react';
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/solid';
import clsx from 'clsx';

import { TPaginationProps } from './pagination.types';
import { usePagination } from '../../../hooks';

export const Pagination = ({
    onPaginate,
    currentPage,
    pageSize,
    totalItems,
    totalPages,
    initialPage = 1,
}: TPaginationProps): ReactElement => {
    const {
        activePage,
        pageNumbers,
        hasNextPage,
        hasPrevPage,
        setNextPageActive,
        setPrevPageActive,
        setActivePage,
    } = usePagination({
        totalPages,
        initialPage,
        onPaginate,
    });

    const pageFrom = pageSize * (currentPage - 1) + 1;
    const pageTo =
        activePage === totalPages ? totalItems : pageFrom + pageSize - 1;

    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                <button
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    disabled={!hasPrevPage}
                    onClick={() => {
                        setPrevPageActive();
                    }}
                >
                    Previous
                </button>
                <button
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    disabled={!hasNextPage}
                    onClick={() => {
                        setNextPageActive();
                    }}
                >
                    Next
                </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                {totalItems > 0 && (
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing{' '}
                            <span className="font-medium">{pageFrom}</span> to{' '}
                            <span className="font-medium">{pageTo}</span> of{' '}
                            <span className="font-medium">{totalItems}</span>{' '}
                            results
                        </p>
                    </div>
                )}
                <div>
                    <nav
                        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                        aria-label="Pagination"
                    >
                        <button
                            className={clsx(
                                'relative w-10 inline-flex items-center justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium',
                                hasPrevPage
                                    ? 'text-gray-500 hover:bg-gray-50'
                                    : 'text-gray-300 cursor-default',
                            )}
                            disabled={!hasPrevPage}
                            onClick={() => {
                                setActivePage(1);
                            }}
                        >
                            <span className="sr-only">First</span>
                            <ChevronDoubleLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                        <button
                            className={clsx(
                                'relative w-10 inline-flex items-center justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium',
                                hasPrevPage
                                    ? 'text-gray-500 hover:bg-gray-50'
                                    : 'text-gray-300 cursor-default',
                            )}
                            disabled={!hasPrevPage}
                            onClick={() => {
                                setPrevPageActive();
                            }}
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                        {pageNumbers.map(pageNumber => (
                            <button
                                key={pageNumber}
                                className={clsx(
                                    'relative w-10 inline-flex items-center justify-center py-2 border border-gray-300 bg-white text-sm font-medium',
                                    pageNumber === activePage
                                        ? 'text-primary-600 bg-primary-100 cursor-default'
                                        : 'text-gray-700 hover:bg-gray-50',
                                )}
                                disabled={pageNumber === activePage}
                                onClick={() => {
                                    setActivePage(pageNumber);
                                }}
                            >
                                {pageNumber}
                            </button>
                        ))}

                        <button
                            className={clsx(
                                'relative w-10 inline-flex items-center justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium',
                                hasNextPage
                                    ? 'text-gray-500 hover:bg-gray-50'
                                    : 'text-gray-300 cursor-default',
                            )}
                            disabled={!hasNextPage}
                            onClick={() => {
                                setNextPageActive();
                            }}
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                        <button
                            className={clsx(
                                'relative w-10 inline-flex items-center justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium',
                                hasNextPage
                                    ? 'text-gray-500 hover:bg-gray-50'
                                    : 'text-gray-300 cursor-default',
                            )}
                            disabled={!hasNextPage}
                            onClick={() => {
                                setActivePage(totalPages);
                            }}
                        >
                            <span className="sr-only">Last</span>
                            <ChevronDoubleRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};
