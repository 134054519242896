import { useEffect, useRef, ReactElement } from 'react';

import { TScrollIntoViewProps } from './scroll-into-view.types';

export const ScrollIntoView = (props: TScrollIntoViewProps): ReactElement => {
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollIntoView({ block: 'center' });
        }
    }, [containerRef]);

    return <div ref={containerRef} {...props} />;
};
