export const modifyClassName = (
    className: string,
    add?: string,
    remove?: string,
): string => {
    if (!add && !remove) {
        return className;
    }

    let modifiedClassName = className;

    if (remove) {
        const replaceRegex = new RegExp(remove.split(' ').join('|'), 'g');
        modifiedClassName = className.replace(replaceRegex, '');
    }

    if (add) {
        modifiedClassName = `${modifiedClassName} ${add}`;
    }

    return modifiedClassName.trim().replace(/\s\s+/g, ' ');
};
