import { ReactElement } from 'react';

import { TActionPanelProps } from './action-panel.types';

export const ActionPanel = ({
    heading,
    subHeading,
    Buttons,
    HeadingItems,
}: TActionPanelProps): ReactElement => (
    <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6 flex flex-col md:flex-row md:justify-between">
            <div>
                <div className="flex flex-col items-start sm:flex-row sm:items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {heading}
                    </h3>
                    {HeadingItems && <HeadingItems />}
                </div>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div className="max-w-xl text-sm text-gray-500">
                        <p>{subHeading}</p>
                    </div>
                </div>
            </div>
            {Buttons && (
                <div className="mt-5 md:mt-0 md:ml-6 md:flex-shrink-0 md:flex md:items-center">
                    <Buttons />
                </div>
            )}
        </div>
    </div>
);
