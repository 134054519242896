import { useEffect, useState, ReactElement } from 'react';
import clsx from 'clsx';

import styles from './loading.styles.module.css';

export const Loading = (): ReactElement => {
    const [showSpinner, setShowSpinner] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowSpinner(true);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className="h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            {showSpinner && (
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    <div className={clsx(styles.loader, 'text-primary-500')}>
                        Loading
                    </div>
                </h2>
            )}
        </div>
    );
};
