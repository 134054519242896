import faker from 'faker';

import {
    TAllocatedSim,
    TApiListResponse,
    TSuccessApiListResponse,
} from '../types';

const wait = (ms: number): Promise<void> =>
    new Promise(resolve => setTimeout(resolve, ms));

const randomNumberOfLength = (length: number): number => {
    return (
        Math.floor(Math.random() * (9 * Math.pow(10, length - 1))) +
        Math.pow(10, length - 1)
    );
};

const randomFromArray = <T = unknown>(arr: T[]): T =>
    arr[Math.floor(Math.random() * arr.length)];

const random = {
    iccid: (): TAllocatedSim['iccid'] =>
        `894412266660${randomNumberOfLength(7)}`,
    msisdn: (): TAllocatedSim['msisdn'] => `447${randomNumberOfLength(9)}`,
    status: (): TAllocatedSim['status'] =>
        randomFromArray(['active', 'suspended']),
    label: (): TAllocatedSim['label'] =>
        randomFromArray(['', faker.random.words(2)]),
    type: (): TAllocatedSim['type'] => randomFromArray(['mno', 'fmc']),
    enterprise: (): TAllocatedSim['enterprise'] => null,
};

const getDataOfLength = (length = 91): TApiListResponse<TAllocatedSim> => ({
    success: true,
    status: 200,
    count: length,
    data: Array.from(Array(length))
        .map(() => ({
            iccid: random.iccid(),
            msisdn: random.msisdn(),
            status: random.status(),
            label: random.label(),
            type: random.type(),
            enterprise: random.enterprise(),
        }))
        .sort((a, b) => Number(a.iccid) - Number(b.iccid)),
});

const mockData = getDataOfLength() as TSuccessApiListResponse<TAllocatedSim>;

export const mockListAllocatedSims = async (
    _spid: string,
    { start = 1, limit }: { start?: number; limit: number },
): Promise<TApiListResponse<TAllocatedSim>> => {
    await wait(500);

    return {
        ...mockData,
        data: mockData.data.slice(start - 1, start - 1 + limit),
    };
};
