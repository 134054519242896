import { ReactElement } from 'react';
import {
    Input,
    InputGroup,
    Label,
    Toggle,
} from '@joshfarrant/tailwind-ui-react';
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
} from '@heroicons/react/solid';
import { formatDistanceToNowStrict, parseJSON } from 'date-fns';
import clsx from 'clsx';

import { TInput, TSipRegistrationProps } from './sip-registration.types';
import { TLastCallObject } from '../../../types';
import { Switch } from '@headlessui/react';

export const SipRegistration = ({
    persistedRegistrationConfig,
    registrationStatus,
    registrationConfig,
    onConfigChange,
    disabled,
}: TSipRegistrationProps): ReactElement => {
    const {
        sip_host,
        sip_domain,
        sip_user,
        sip_auth_user,
        sip_expires,
        sip_password,
        enable_registration,
    } = registrationConfig;

    const inputs: TInput[] = [
        {
            id: 'host',
            label: 'Host / SIP Proxy',
            type: 'text',
            value: sip_host,
            setValue: value => {
                onConfigChange({ sip_host: value });
            },
        },
        {
            id: 'domain',
            label: 'SIP Domain',
            type: 'text',
            value: sip_domain,
            setValue: value => {
                onConfigChange({ sip_domain: value });
            },
        },
        {
            id: 'user',
            label: 'User',
            type: 'text',
            value: sip_user,
            setValue: value => {
                onConfigChange({ sip_user: value });
            },
        },
        {
            id: 'authUser',
            label: 'Auth User',
            type: 'text',
            value: sip_auth_user,
            setValue: value => {
                onConfigChange({ sip_auth_user: value });
            },
        },
        {
            id: 'password',
            label: 'Password',
            type: 'password',
            placeholder: '••••••••',
            value: typeof sip_password === 'string' ? sip_password : '',
            setValue: value => {
                onConfigChange({ sip_password: value });
            },
        },
        {
            id: 'expiry',
            label: 'Expiry',
            type: 'number',
            value: String(sip_expires),
            setValue: value => {
                onConfigChange({ sip_expires: parseInt(value, 10) });
            },
        },
    ];

    const { registration, status, last_call: lastCall } = registrationStatus;

    let registrationSipHost;
    let registrationSipDomain;
    let reg_since;
    let reg_expires;

    if (registration) {
        registrationSipHost = registration.sip_host;
        registrationSipDomain = registration.sip_domain;
        reg_since = registration.reg_since;
        reg_expires = registration.reg_expires;
    }

    const hasLastCall = lastCall && !Array.isArray(lastCall);
    const hasLastMoCall =
        hasLastCall && Object.prototype.hasOwnProperty.call(lastCall, 'mo');
    const hasLastMtCall =
        hasLastCall && Object.prototype.hasOwnProperty.call(lastCall, 'mt');

    const formattedLastMo = hasLastMoCall
        ? formatDistanceToNowStrict(
              parseJSON((lastCall as TLastCallObject).mo.date),
          )
        : '';
    const formattedLastMt = hasLastMtCall
        ? formatDistanceToNowStrict(
              parseJSON((lastCall as TLastCallObject).mt.date),
          )
        : '';

    const formattedSince = reg_since
        ? formatDistanceToNowStrict(parseJSON(reg_since))
        : '';
    const formattedExpires = reg_expires
        ? formatDistanceToNowStrict(parseJSON(reg_expires))
        : '';

    const isRegistered = status === 'registered';

    let registeredMessage = isRegistered ? 'Registered' : 'Not Registered';
    let registeredBackgroundColor = isRegistered ? 'bg-green-50' : 'bg-red-50';
    let registeredIcon = isRegistered ? (
        <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" />
    ) : (
        <XCircleIcon className="h-5 w-5 mr-2 text-red-500" />
    );

    if (!persistedRegistrationConfig.enable_registration) {
        registeredMessage = 'Registration Not Enabled';
        registeredBackgroundColor = 'bg-yellow-50';
        registeredIcon = (
            <ExclamationCircleIcon className="h-5 w-5 mr-2 text-yellow-500" />
        );
    }

    return (
        <div className="mt-8 grid grid-cols-2 gap-y-2 md:gap-x-16 md:gap-y-8 pb-4">
            <div className="col-span-2 mb-4 md:mb-0">
                <div className="flex flex-col items-start sm:flex-row smitems-center justify-between pb-6">
                    <h3 className="mb-4 sm:mb-0 text-lg leading-6 font-medium text-gray-900">
                        SIP Registration
                    </h3>
                    <div className="flex flex-row items-center">
                        <Switch.Group>
                            <Toggle
                                srLabel={`${
                                    enable_registration ? 'Disable' : 'Enable'
                                } registration`}
                                enabled={enable_registration}
                                onChange={checked => {
                                    onConfigChange({
                                        enable_registration: checked,
                                    });
                                }}
                                disabled={disabled}
                            />
                            <Switch.Label className="ml-3 text-sm text-gray-500">
                                Enable Registration
                            </Switch.Label>
                        </Switch.Group>
                    </div>
                </div>
                <span className="mt-1 text-sm text-gray-500">
                    <p>
                        When enabled, this SIM will register as a handset to the
                        specified SIP platform using the credentials provided
                        below.
                        <br />
                        <br />
                        You will need to allow SIP registrations from the
                        following IP Addresses;
                    </p>
                    <ul>
                        <li>178.128.36.204</li>
                        <li>178.128.36.209</li>
                    </ul>
                </span>
            </div>
            <div className="col-span-2 md:col-span-1">
                <div className="mt-0 col-span-2 md:col-span-1">
                    <div className="grid grid-cols-1 gap-y-4">
                        {inputs.map(
                            ({
                                id,
                                label,
                                type,
                                value,
                                setValue,
                                placeholder,
                            }) => (
                                <InputGroup
                                    id={id}
                                    key={id}
                                    renderInput={inputProps => (
                                        <Input
                                            {...inputProps}
                                            type={type}
                                            value={value ?? ''}
                                            placeholder={placeholder}
                                            onChange={e =>
                                                setValue(e.target.value)
                                            }
                                            disabled={
                                                !enable_registration || disabled
                                            }
                                        />
                                    )}
                                    renderLabel={labelProps => (
                                        <Label {...labelProps}>{label}</Label>
                                    )}
                                />
                            ),
                        )}
                    </div>
                </div>
            </div>
            <div className="bg-white mt-10 col-span-2 md:mt-0 md:col-span-1 overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                <div
                    className={clsx(
                        'px-4 py-5 sm:px-6 flex flex-row items-center',
                        registeredBackgroundColor,
                    )}
                >
                    {registeredIcon}
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {registeredMessage}
                    </h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 md:gap-y-1 md:py-3 lg:gap-4 lg:py-5">
                            <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                                Registered to
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {registrationSipHost || 'N/A'}
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 md:gap-y-1 md:py-3 lg:gap-4 lg:py-5">
                            <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                                Registered Since
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {formattedSince
                                    ? `${formattedSince} ago`
                                    : 'N/A'}
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 md:gap-y-1 md:py-3 lg:gap-4 lg:py-5">
                            <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                                Registration Expires
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {formattedExpires
                                    ? `in ${formattedExpires}`
                                    : 'N/A'}
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 md:gap-y-1 md:py-3 lg:gap-4 lg:py-5">
                            <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                                Last MO Call
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {hasLastMoCall ? (
                                    <>
                                        {formattedLastMo} ago
                                        <br />
                                        To{' '}
                                        {
                                            (lastCall as TLastCallObject).mo
                                                .dialled
                                        }
                                    </>
                                ) : (
                                    'Unknown'
                                )}
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 md:gap-y-1 md:py-3 lg:gap-4 lg:py-5">
                            <dt className="text-sm font-medium text-gray-500 col-span-1 md:col-span-3 lg:col-span-1">
                                Last MT Call
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {hasLastMtCall ? (
                                    <>
                                        {formattedLastMt} ago
                                        <br />
                                        From{' '}
                                        {(lastCall as TLastCallObject).mt.from}
                                    </>
                                ) : (
                                    'Unknown'
                                )}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
};
