import {
    TConfigAction,
    TPartialSimConfig,
    TSetSimConfigChangesAction,
    TResetSimConfigChangesAction,
    TState,
    TPartialRegistrationConfig,
    TSetRegistrationConfigChangesAction,
    TResetRegistrationConfigChangesAction,
    TPartialInboundConfig,
    TSetSimInboundConfigChangesAction,
    TResetSimInboundConfigChangesAction,
    TPartialSimDetail,
    TSetSimDetailChangesAction,
    TResetSimDetailChangesAction,
    TSetErrorsAction,
    TResetErrorsAction,
} from './allocated-sim.types';

export const initialState: TState = {
    simConfigChanges: {},
    simDetailChanges: {},
    registrationConfigChanges: {},
    simInboundConfigChanges: {},
    meta: {
        isDirty: false,
        errors: [],
    },
};

export const setSimConfigChanges = (
    payload: TPartialSimConfig,
): TSetSimConfigChangesAction => ({ type: 'setSimConfigChanges', payload });

export const resetSimConfigChanges = (): TResetSimConfigChangesAction => ({
    type: 'resetSimConfigChanges',
    payload: {},
});

export const setSimDetailChanges = (
    payload: TPartialSimDetail,
): TSetSimDetailChangesAction => ({ type: 'setSimDetailChanges', payload });

export const resetSimDetailChanges = (): TResetSimDetailChangesAction => ({
    type: 'resetSimDetailChanges',
    payload: {},
});

export const setRegistrationConfigChanges = (
    payload: TPartialRegistrationConfig,
): TSetRegistrationConfigChangesAction => ({
    type: 'setRegistrationConfigChanges',
    payload,
});

export const resetRegistrationConfigChanges = (): TResetRegistrationConfigChangesAction => ({
    type: 'resetRegistrationConfigChanges',
    payload: {},
});

export const setSimInboundConfigChanges = (
    payload: TPartialInboundConfig,
): TSetSimInboundConfigChangesAction => ({
    type: 'setSimInboundConfigChanges',
    payload,
});

export const resetSimInboundConfigChanges = (): TResetSimInboundConfigChangesAction => ({
    type: 'resetSimInboundConfigChanges',
    payload: {},
});

export const setErrors = (errors: string[]): TSetErrorsAction => ({
    type: 'setErrors',
    payload: errors,
});

export const resetErrors = (): TResetErrorsAction => ({
    type: 'resetErrors',
    payload: {},
});

export const reducer = (state: TState, action: TConfigAction): TState => {
    const { type, payload } = action;

    switch (type) {
        case 'setSimConfigChanges':
            return {
                ...state,
                simConfigChanges: {
                    ...state.simConfigChanges,
                    ...(payload as TPartialSimConfig),
                },
                meta: {
                    ...state.meta,
                    isDirty: true,
                },
            };
        case 'setSimDetailChanges':
            return {
                ...state,
                simDetailChanges: {
                    ...state.simDetailChanges,
                    ...(payload as TPartialSimDetail),
                },
                meta: {
                    ...state.meta,
                    isDirty: true,
                },
            };
        case 'setRegistrationConfigChanges':
            return {
                ...state,
                registrationConfigChanges: {
                    ...state.registrationConfigChanges,
                    ...(payload as TPartialRegistrationConfig),
                },
                meta: {
                    ...state.meta,
                    isDirty: true,
                },
            };
        case 'setSimInboundConfigChanges':
            return {
                ...state,
                simInboundConfigChanges: {
                    ...state.simInboundConfigChanges,
                    ...(payload as TPartialInboundConfig),
                },
                meta: {
                    ...state.meta,
                    isDirty: true,
                },
            };
        case 'setErrors':
            return {
                ...state,

                meta: {
                    ...state.meta,
                    errors: payload as string[],
                },
            };
        case 'resetSimConfigChanges':
            return {
                ...state,
                simConfigChanges: {
                    ...(payload as TPartialSimConfig),
                },
                meta: {
                    ...state.meta,
                    isDirty: false,
                },
            };
        case 'resetSimDetailChanges':
            return {
                ...state,
                simDetailChanges: {
                    ...(payload as TPartialSimDetail),
                },
                meta: {
                    ...state.meta,
                    isDirty: false,
                },
            };
        case 'resetRegistrationConfigChanges':
            return {
                ...state,
                registrationConfigChanges: {
                    ...(payload as TPartialRegistrationConfig),
                },
                meta: {
                    ...state.meta,
                    isDirty: false,
                },
            };
        case 'resetSimInboundConfigChanges':
            return {
                ...state,
                simInboundConfigChanges: {
                    ...(payload as TPartialInboundConfig),
                },
                meta: {
                    ...state.meta,
                    isDirty: false,
                },
            };
        case 'resetErrors':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    errors: [],
                },
            };
        default:
            console.warn('Unknown action passed to reducer: ', action);
            return state;
    }
};
