import { useState } from 'react';

import { TUseToggle } from './use-toggle.types';

export const useToggle = (initialState = false): TUseToggle => {
    const [value, setValue] = useState<boolean>(initialState);
    const toggleValue = () => setValue(x => !x);

    return [value, toggleValue];
};
