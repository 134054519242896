import { ReactElement } from 'react';
import { TSettingProps } from './setting.types';

export const Setting = ({
    heading,
    description,
    children,
}: TSettingProps): ReactElement => (
    <>
        <div className="mt-0 md:mt-0 col-span-5 md:col-span-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
                {heading}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
        <div className="col-span-5 mb-8 md:mt-0 md:col-span-3">
            <div className="space-y-6">
                <div className="grid grid-cols-1 gap-6">
                    <div className="col-span-1">{children}</div>
                </div>
            </div>
        </div>
    </>
);
