import { useState } from 'react';

import {
    createNumberRange,
    createArrayFromRange,
    ensureNumberIsWithinRange,
    ensureRangeArrayIsWithinRange,
} from './use-pagination.helpers';
import { TUsePagination, TUsePaginationOptions } from './use-pagination.types';

export const usePagination = ({
    totalPages,
    initialPage = 1,
    pageNumbersCount: oddOrEvenPageNumbersCount = 5,
    onPaginate,
}: TUsePaginationOptions): TUsePagination => {
    if (typeof totalPages !== 'number' || totalPages <= 0) {
        totalPages = 1;
    }

    const availableRange = createNumberRange(1, totalPages);

    const sanitizePage = ensureNumberIsWithinRange(availableRange);

    const safeInitialPage = sanitizePage(initialPage);

    const [activePage, unsafeSetActivePage] = useState(safeInitialPage);

    // Ensure that pageNumbersCount is always an odd number
    let pageNumbersCount = oddOrEvenPageNumbersCount;
    if (oddOrEvenPageNumbersCount % 2 === 0) {
        console.warn(
            'pageNumbersCount option passed to usePagination hook should be an odd number. If an even number is passed it will be incremented to an odd number.',
        );
        pageNumbersCount = oddOrEvenPageNumbersCount + 1;
    }

    let pageNumbers: number[] = [];

    if (totalPages <= pageNumbersCount) {
        pageNumbers = createArrayFromRange(createNumberRange(1, totalPages));
    } else {
        // Create an array centered on activePage, of length pageNumbersCount
        const halvedPageNumbersCount = Math.floor(pageNumbersCount / 2);
        const fromPage = activePage - halvedPageNumbersCount;
        const toPage = activePage + halvedPageNumbersCount;
        const unsafePageNumbers = createArrayFromRange(
            createNumberRange(fromPage, toPage),
        );

        // Shift that array to ensure that all numbers in the array are within 1 <= x <= totalPages
        pageNumbers = ensureRangeArrayIsWithinRange(availableRange)(
            unsafePageNumbers,
        );
    }

    // Ensures activePage is always set to a valid number, and calls onPaginate if activePage changes
    function setActivePage(page: number): void {
        const previousPage = activePage;
        const nextPage = sanitizePage(page);

        if (nextPage !== previousPage) {
            unsafeSetActivePage(nextPage);
            onPaginate?.(nextPage);
        }
    }

    function setNextPageActive(): void {
        setActivePage(activePage + 1);
    }

    function setPreviousPageActive(): void {
        setActivePage(activePage - 1);
    }

    return {
        activePage,
        pageNumbers,
        setActivePage,
        setNextPageActive,
        setPrevPageActive: setPreviousPageActive,
        hasNextPage: activePage < totalPages,
        hasPrevPage: activePage > 1,
    };
};
