import { forwardRef, ReactElement } from 'react';
import { Button, TButtonProps } from '@joshfarrant/tailwind-ui-react';
import clsx from 'clsx';

import { modifyClassName } from '../../../utils';

export const PrimaryButton = forwardRef(
    ({ className = '', ...props }: TButtonProps, ref): ReactElement => (
        <Button
            className={modifyClassName(
                className,
                clsx(
                    'border-transparent shadow-sm text-white',
                    props.disabled
                        ? 'bg-primary-300 cursor-not-allowed'
                        : 'bg-primary-600 hover:bg-primary-700 focus:ring-primary-500',
                ),
            )}
            {...props}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
        />
    ),
);

export const SecondaryButton = forwardRef(
    ({ className = '', ...props }: TButtonProps, ref): ReactElement => (
        <Button
            className={modifyClassName(
                className,
                'border-transparent text-primary-700 bg-primary-100 hover:bg-primary-200 focus:ring-primary-500',
            )}
            {...props}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
        />
    ),
);

export const WhiteButton = forwardRef(
    ({ className = '', ...props }: TButtonProps, ref): ReactElement => (
        <Button
            className={modifyClassName(
                className,
                clsx(
                    'border-gray-300 shadow-sm bg-white',
                    props.disabled
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-50 focus:ring-primary-500',
                ),
            )}
            {...props}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
        />
    ),
);

export const DangerButton = forwardRef(
    ({ className = '', ...props }: TButtonProps, ref): ReactElement => (
        <Button
            className={modifyClassName(
                className,
                clsx(
                    'border-danger-300 shadow-sm bg-white',
                    props.disabled
                        ? 'text-danger-400 cursor-not-allowed'
                        : 'text-danger-700 hover:bg-danger-50 focus:ring-primary-500',
                ),
            )}
            {...props}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
        />
    ),
);
