import { TSimStatus } from '../types';

type TStatusBadgeColor = 'primary' | 'green' | 'danger';

const colorMap = new Map<TSimStatus, TStatusBadgeColor>([
    ['available', 'primary'],
    ['active', 'green'],
    ['suspended', 'danger'],
]);

export const getStatusBadgeColor = (status: TSimStatus): TStatusBadgeColor =>
    colorMap.get(status) ?? 'primary';
