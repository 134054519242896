import { ReactElement } from 'react';
import clsx from 'clsx';
import { RadioGroup } from '@headlessui/react';
import { Input, InputGroup, Label } from '@joshfarrant/tailwind-ui-react';

import { Setting } from '../../';
import { TInboundCallsProps } from './inbound-calls.types';

export const InboundCalls = ({
    inboundConfig,
    onConfigChange,
    disabled,
}: TInboundCallsProps): ReactElement => {
    const { inbound_method, inbound_endpoint } = inboundConfig;

    const options = [
        {
            value: 'direct',
            name: 'Direct to SIM',
            description:
                'Inbound calls to the MSISDN will be routed directly to the SIM.',
        },
        {
            value: 'endpoint',
            name: 'SIP Endpoint',
            description:
                'Inbound calls to the MSISDN will be routed to the SIP host specified.',
            content: (
                <InputGroup
                    className="mt-5"
                    id="endpoint"
                    renderInput={inputProps => (
                        <Input
                            {...inputProps}
                            value={inbound_endpoint}
                            onChange={e =>
                                onConfigChange({
                                    inbound_endpoint: e.target.value,
                                })
                            }
                            onClick={e => e.stopPropagation()}
                            disabled={disabled}
                        />
                    )}
                    renderLabel={labelProps => (
                        <Label {...labelProps}>
                            SIP Hostname (e.g.
                            sip.example.com:5060;transport=tcp)
                        </Label>
                    )}
                />
            ),
        },
    ];

    return (
        <div className="mt-8 grid grid-cols-5 gap-y-2 md:gap-x-8 md:gap-y-8">
            <div className="col-span-full mb-8 md:mb-0">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Inbound Calls
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Inbound calls to the SIM&apos;s assigned MSISDN will be
                    routed as configured below.
                </p>
            </div>
            <Setting
                heading="Inbound Call Routing"
                description="Inbound calls to the MSISDN can be routed directly to the SIM, or to a SIP endpoint of your choice for you to route onward to the SIM or other service."
            >
                <RadioGroup
                    value={inbound_method}
                    onChange={value => {
                        onConfigChange({
                            inbound_method: value,
                        });
                    }}
                    disabled={disabled}
                >
                    <RadioGroup.Label className="sr-only">
                        Call Routing
                    </RadioGroup.Label>
                    <div className="bg-white rounded-md -space-y-px">
                        {options.map((setting, i) => (
                            <RadioGroup.Option
                                key={setting.name}
                                value={setting.value}
                                className={({ checked }) =>
                                    clsx(
                                        i === 0
                                            ? 'rounded-tl-md rounded-tr-md'
                                            : '',
                                        i === options.length - 1
                                            ? 'rounded-bl-md rounded-br-md'
                                            : '',
                                        checked
                                            ? 'bg-primary-50 border-primary-200 z-10'
                                            : 'border-gray-200',
                                        'relative border p-4 flex cursor-pointer focus:outline-none',
                                    )
                                }
                            >
                                {({ checked }) => (
                                    <>
                                        <span
                                            className={clsx(
                                                checked
                                                    ? 'bg-primary-600 border-transparent'
                                                    : 'bg-white border-gray-300',
                                                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                            )}
                                            aria-hidden="true"
                                        >
                                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                                        </span>
                                        <div className="ml-3 flex flex-col flex-1">
                                            <RadioGroup.Label
                                                as="span"
                                                className={clsx(
                                                    checked
                                                        ? 'text-primary-900'
                                                        : 'text-gray-900',
                                                    'block text-sm font-medium',
                                                )}
                                            >
                                                {setting.name}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                                as="span"
                                                className={clsx(
                                                    checked
                                                        ? 'text-primary-700'
                                                        : 'text-gray-500',
                                                    'block text-sm',
                                                )}
                                            >
                                                {setting.description}
                                            </RadioGroup.Description>
                                            {setting.content}
                                        </div>
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </Setting>
        </div>
    );
};
