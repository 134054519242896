import { TDataBundle, TRoaming } from '../types';

export const DATA_BUNDLES: Record<TDataBundle | 'no', string> = {
    no: 'Data barred',
    '1gb': '1 GB per month',
    '3gb': '3 GB per month',
    '10gb': '10 GB per month',
    '30gb': '30 GB per month',
    '100gb': '100 GB per month',
};

export const ROAMING: Record<TRoaming, string> = {
    no: 'Disabled',
    eu: 'EU only',
    global: 'Global',
};
