import { createContext, useContext } from 'react';

import { TAppContext } from '.';

const initialContext = {
    showAllSims: true,
    setShowAllSims: () => {},
    activatedSims: [],
    setActivatedSims: () => {},
};

export const AppContext = createContext<TAppContext>(initialContext);
export const useApp = (): TAppContext => useContext(AppContext);
