export const formatPaginationParams = ({
    pageIndex,
    pageSize,
}: {
    pageIndex: number;
    pageSize: number;
}): { start?: number; limit: number } => {
    const obj = { limit: pageSize };

    return {
        ...obj,
        start: pageSize * (pageIndex - 1),
    };
};
