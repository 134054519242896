import { Fragment, ReactElement, useState, useRef, useCallback } from 'react';
import { InputGroup, Input, Label } from '@joshfarrant/tailwind-ui-react';
import { useCombobox } from 'downshift';
import { Transition } from '@headlessui/react';
import { PhoneIcon, TagIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useHotkeys } from 'react-hotkeys-hook';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import {
    checkRequiredParams,
    isErrorResponse,
    modifyClassName,
} from '../../../utils';
import { TSearchResult, TApiListResponse } from '../../../types';
import { useApp, useUser } from '../../../contexts';
import { searchSims } from '../../../api';

const minSearchLength = 3;

const emptyResponse: TApiListResponse<TSearchResult> = {
    success: true,
    status: 204,
    data: [],
    count: 0,
};

export const Search = (): ReactElement => {
    const router = useRouter();
    const { spid } = router.query;
    const { auth } = useUser();
    const [inputItems, setInputItems] = useState<TSearchResult[]>([]);
    const [query, setQuery] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const { showAllSims } = useApp();

    const debouncedSetQuery = useCallback(debounce(setQuery, 300), []);

    useHotkeys('cmd+k, ctrl+k', e => {
        e.preventDefault();
        inputRef?.current?.focus();
    });

    const searchQuery = useQuery<TApiListResponse<TSearchResult>, string[]>(
        ['searchSims', { spid, query, showAllSims }],
        async () => {
            if (!query || query.length < minSearchLength) {
                return emptyResponse;
            }

            checkRequiredParams(spid);

            const { res, json } = await searchSims(
                auth.header,
                spid as string,
                query,
                !showAllSims,
            );

            if (!json || res?.status === 204) {
                return emptyResponse;
            }

            if (isErrorResponse(json)) {
                throw json.errors;
            }

            return json;
        },
        {
            onSuccess: res => {
                if (!isErrorResponse(res)) {
                    setInputItems(res.data);
                }
            },
            cacheTime: 0,
        },
    );

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
        items: inputItems,
        itemToString: () => '',
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                const { iccid } = selectedItem;
                router.push(`/${spid}/sims/allocated/${iccid}`);
            }
        },
        onInputValueChange: ({ inputValue }) => {
            const value = inputValue?.toLowerCase().trim();

            debouncedSetQuery(value ?? '');
        },
    });

    const isMac = window?.navigator.platform.toUpperCase().includes('MAC');

    const content = (() => {
        if (searchQuery.isLoading) {
            return (
                <li className="block px-4 py-2 text-sm text-gray-500">
                    Loading...
                </li>
            );
        }

        if (inputItems.length === 0) {
            if (query.length < minSearchLength) {
                return (
                    <li className="block px-4 py-2 text-sm text-gray-500">
                        Enter 3 or more characters
                    </li>
                );
            } else {
                return (
                    <li className="block px-4 py-2 text-sm text-gray-500">
                        No results found
                    </li>
                );
            }
        }

        return inputItems.map((item, index) => {
            const { msisdn, iccid, label } = item;
            return (
                <li
                    key={iccid}
                    className={clsx(
                        highlightedIndex === index
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                        'block px-4 py-3 text-sm',
                    )}
                    {...getItemProps({ item, index })}
                >
                    <p className="text-sm font-medium text-gray-900">{iccid}</p>
                    <p className="flex items-center text-sm text-gray-500">
                        <PhoneIcon
                            className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                            aria-hidden="true"
                        />
                        {msisdn}
                    </p>
                    {label && (
                        <p className="flex items-center text-sm text-gray-500">
                            <TagIcon
                                className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                                aria-hidden="true"
                            />
                            {label}
                        </p>
                    )}
                </li>
            );
        });
    })();

    return (
        <div
            className="ap-search-container relative z-10"
            {...getComboboxProps()}
        >
            <InputGroup
                className="max-w-xs mx-auto w-full lg:max-w-md"
                id="search"
                renderInput={inputProps => (
                    <Input
                        {...inputProps}
                        leadingIcon="Search"
                        placeholder="Search"
                        renderInput={({ className = '', ...htmlProps }) => (
                            <input
                                className={modifyClassName(
                                    className,
                                    'text-white bg-white bg-opacity-20 py-2 pr-3 border border-transparent leading-5 focus:text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0',
                                    'shadow-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300',
                                )}
                                {...htmlProps}
                                {...getInputProps({
                                    ref: inputRef,
                                    onKeyDown: e => {
                                        if (
                                            e.key === 'Enter' &&
                                            inputItems.length === 1
                                        ) {
                                            const { iccid } = inputItems[0];

                                            router.push(
                                                `/${spid}/sims/allocated/${iccid}`,
                                            );
                                        }
                                    },
                                })}
                            />
                        )}
                    />
                )}
                renderLabel={() => (
                    <Label {...getLabelProps()} hidden>
                        Search
                    </Label>
                )}
            />
            <div className="ap-keyboard-container absolute top-1/2 transform -translate-y-1/2 right-2 hidden lg:block text-gray-100 text-sm leading-5 mt-0.5 py-0.5 px-1.5 border border-gray-300 rounded-md">
                <span className="sr-only">Press</span>
                <kbd className="font-sans">
                    {isMac ? (
                        <abbr title="Command">⌘</abbr>
                    ) : (
                        <abbr title="Control">Ctrl </abbr>
                    )}
                </kbd>
                <span className="sr-only">and</span>
                <kbd>K</kbd>
                <div className="sr-only">to search</div>
            </div>
            <div
                className="w-full inline-block text-left absolute origin-top-left right-0"
                {...getMenuProps()}
            >
                <Transition
                    show={isOpen}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <div className="mx-auto mt-2 w-full max-w-xs lg:max-w-md rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <ul className="py-1">{content}</ul>
                    </div>
                </Transition>
            </div>
        </div>
    );
};
