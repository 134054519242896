const isString = (value?: string | string[]): value is string => {
    const notString =
        !value || Array.isArray(value) || typeof value !== 'string';

    return !notString;
};

export const checkRequiredParams = (
    ...args: (string | string[] | undefined)[]
): void | never => {
    args.forEach(arg => {
        if (!isString(arg)) {
            throw [
                'Something went wrong with this application. Please try again or raise a support ticket if this continues.',
            ];
        }
    });
};
