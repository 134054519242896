import { useState, PropsWithChildren, ReactElement } from 'react';
import { useLocalStorage } from '../../hooks';
import { AppContext } from './app.context';

export function AppProvider({
    children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
    const [showAllSims, setShowAllSims] = useLocalStorage<boolean>(
        'ap-show-all-sims',
        true,
    );
    const [activatedSims, setActivatedSims] = useState<string[]>([]);

    return (
        <AppContext.Provider
            value={{
                showAllSims,
                setShowAllSims,
                activatedSims,
                setActivatedSims,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
