import { ReactElement, useState } from 'react';
import { useRouter } from 'next/router';
import { Input, InputGroup, Label } from '@joshfarrant/tailwind-ui-react';
import { useMutation } from 'react-query';
import Link from 'next/link';

import { PrimaryButton } from '../../atoms';
import {
    TChangePasswordApiResponse,
    TResetPassword,
    TSuccessApiObjectResponse,
} from '../../../types';
import { changePasswordWithToken, resetPassword } from '../../../api';
import { isErrorResponse } from '../../../utils';

export const ForgotPassword = (): ReactElement => {
    const router = useRouter();
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [errors, setErrors] = useState<string[]>([]);

    const resetPasswordMutation = useMutation<
        TSuccessApiObjectResponse<TResetPassword>,
        string[],
        null
    >(
        async () => {
            const res = await resetPassword(email);

            if (isErrorResponse(res)) {
                throw res.errors;
            }

            return res;
        },
        {
            onSuccess: () => {
                setErrors([]);
                router.push({
                    pathname: '/forgot-password',
                    query: { done: true },
                });
            },
            onError: (errors: unknown) => {
                if (Array.isArray(errors)) {
                    setErrors(errors);
                    return;
                }

                setErrors([
                    'Something went wrong. Please try again or raise a support ticket if this continues.',
                ]);
            },
        },
    );

    const changePasswordMutation = useMutation<
        TChangePasswordApiResponse,
        string[],
        string
    >(
        async passedToken => {
            const res = await changePasswordWithToken(passedToken, newPassword);

            if (isErrorResponse(res)) {
                throw res.errors;
            }

            return res;
        },
        {
            onSuccess: () => {
                setErrors([]);
                router.push({
                    pathname: '/login',
                    query: { passwordReset: true },
                });
            },
            onError: (errors: unknown) => {
                if (Array.isArray(errors)) {
                    setErrors(errors);
                    return;
                }

                setErrors([
                    'Something went wrong. Please try again or raise a support ticket if this continues.',
                ]);
            },
        },
    );

    const { token, done } = router.query;

    const showPasswordMatchError =
        newPassword !== newPasswordConfirm && newPasswordConfirm.length > 0;

    const isChangePasswordButtonDisabled =
        changePasswordMutation.isLoading || showPasswordMatchError;

    let content = (
        <>
            <p className="mb-6">
                To reset your password, enter the email address you use to sign
                in.
            </p>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    resetPasswordMutation.mutate(null);
                }}
            >
                <InputGroup
                    id="email"
                    renderInput={inputProps => (
                        <Input
                            {...inputProps}
                            type="email"
                            name="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                        />
                    )}
                    renderLabel={labelProps => (
                        <Label {...labelProps}>Email address</Label>
                    )}
                />

                {errors.length > 0 && (
                    <div className="mt-3 text-sm text-danger-600">
                        {errors.join(', ')}
                    </div>
                )}

                <PrimaryButton
                    className="mt-6"
                    type="submit"
                    fullWidth
                    disabled={resetPasswordMutation.isLoading}
                >
                    Get reset link
                </PrimaryButton>
            </form>
        </>
    );

    if (done) {
        content = (
            <p>
                Check your <strong>{email}</strong> inbox for instructions from
                us on how to reset your password.
            </p>
        );
    }

    if (token) {
        content = (
            <>
                <p className="mb-6">Please enter your new password.</p>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        changePasswordMutation.mutate(String(token));
                    }}
                >
                    <InputGroup
                        id="new-password"
                        renderInput={inputProps => (
                            <Input
                                {...inputProps}
                                required
                                value={newPassword}
                                type="password"
                                autoComplete="new-password"
                                onChange={e => {
                                    setNewPassword(e.target.value);
                                }}
                            />
                        )}
                        renderLabel={labelProps => (
                            <Label {...labelProps}>New Password</Label>
                        )}
                    />

                    <InputGroup
                        className="mt-6"
                        id="confirm-new-password"
                        hasError={showPasswordMatchError}
                        helpText={
                            showPasswordMatchError
                                ? "Your passwords don't match"
                                : ''
                        }
                        renderInput={inputProps => (
                            <Input
                                {...inputProps}
                                required
                                value={newPasswordConfirm}
                                type="password"
                                autoComplete="new-password"
                                onChange={e => {
                                    setNewPasswordConfirm(e.target.value);
                                }}
                            />
                        )}
                        renderLabel={labelProps => (
                            <Label {...labelProps}>Confirm New Password</Label>
                        )}
                    />

                    {errors.length > 0 && (
                        <div className="mt-3 text-sm text-danger-600">
                            {errors.join(', ')}
                        </div>
                    )}

                    <PrimaryButton
                        className="mt-6"
                        type="submit"
                        fullWidth
                        disabled={isChangePasswordButtonDisabled}
                    >
                        Change password
                    </PrimaryButton>
                </form>
            </>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-24 w-auto"
                    src="/logo@2x.png"
                    alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {token ? 'Change' : 'Reset'} your password
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    {content}
                </div>
                {!done && (
                    <p className="mx-auto mt-3 text-center">
                        Never mind!{' '}
                        <Link href="/login">
                            <a className="font-medium text-blue-600 hover:text-blue-500">
                                Take me back to login
                            </a>
                        </Link>
                    </p>
                )}
            </div>
        </div>
    );
};
