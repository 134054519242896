import { useState, ReactElement } from 'react';
import { Input, InputGroup, Label } from '@joshfarrant/tailwind-ui-react';
import { useMutation } from 'react-query';
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { useToasts } from 'react-toast-notifications';

import { Layout, PrimaryButton, ScrollIntoView, Setting } from '../..';
import { useUser } from '../../../contexts';
import { TChangePasswordApiResponse } from '../../../types';
import { isErrorResponse } from '../../../utils';
import { changePassword } from '../../../api';

export const ChangePassword = (): ReactElement => {
    const { auth } = useUser();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState(false);
    const { addToast } = useToasts();

    const pageTitle = 'Change Password : ApaloSIM';

    const changePasswordMutation = useMutation<
        TChangePasswordApiResponse,
        string[],
        null
    >(async () => {
        const res = await changePassword(auth.header, oldPassword, newPassword);

        if (isErrorResponse(res)) {
            throw res.errors;
        }

        return res;
    });

    const hasErrors = errors.length > 0;
    const showPasswordMatchError =
        newPassword !== newPasswordConfirm && newPasswordConfirm.length > 0;

    const disableButton =
        changePasswordMutation.isLoading || showPasswordMatchError;

    return (
        <>
            <Layout pageTitle={pageTitle} heading="Change Password">
                {hasErrors && (
                    <ScrollIntoView className="bg-red-50 shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6 flex flex-col items-start">
                            <div className="mb-2 flex flex-row items-center">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationCircleIcon
                                        className="h-6 w-6 text-red-600"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-1 text-lg leading-6 font-medium text-red-800">
                                    Error changing password
                                </div>
                            </div>
                            <div className="px-8 pb-3 flex flex-col">
                                <ul className="list-disc list-inside">
                                    {errors.map(error => (
                                        <li key={error}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </ScrollIntoView>
                )}
                {success && (
                    <ScrollIntoView className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6 flex flex-col items-start">
                            <div className="flex flex-row items-center">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                    <CheckCircleIcon
                                        className="h-6 w-6 text-green-500"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-1 text-lg leading-6 font-medium">
                                    Password changed successfully
                                </div>
                            </div>
                        </div>
                    </ScrollIntoView>
                )}
                <form
                    className="bg-white shadow sm:rounded-lg"
                    onSubmit={e => {
                        e.preventDefault();
                        changePasswordMutation.mutate(null, {
                            onSuccess: () => {
                                setSuccess(true);
                                setErrors([]);
                                setOldPassword('');
                                setNewPassword('');
                                setNewPasswordConfirm('');
                                addToast(null, {
                                    heading: 'Password Updated Successfully',
                                    appearance: 'success',
                                });
                            },
                            onError: errors => {
                                setErrors(errors);
                                setSuccess(false);
                            },
                        });
                    }}
                >
                    <div className="px-4 pt-5 pb-5 sm:px-6 sm:pt-2 sm:pb-5 flex flex-col items-start">
                        <div className="mt-8 w-full grid grid-cols-5 gap-y-2 md:gap-x-8 md:gap-y-8">
                            <Setting
                                heading="Current Password"
                                description="Enter your existing password."
                            >
                                <InputGroup
                                    className="mt-5"
                                    id="old-password"
                                    renderInput={inputProps => (
                                        <Input
                                            {...inputProps}
                                            value={oldPassword}
                                            type="password"
                                            autoComplete="current-password"
                                            onChange={e => {
                                                setOldPassword(e.target.value);
                                            }}
                                        />
                                    )}
                                    renderLabel={labelProps => (
                                        <Label {...labelProps} hidden>
                                            Old password
                                        </Label>
                                    )}
                                />
                            </Setting>
                            <Setting
                                heading="New Password"
                                description="Choose a new password."
                            >
                                <InputGroup
                                    className="mt-5"
                                    id="new-password"
                                    renderInput={inputProps => (
                                        <Input
                                            {...inputProps}
                                            value={newPassword}
                                            type="password"
                                            autoComplete="new-password"
                                            onChange={e => {
                                                setNewPassword(e.target.value);
                                            }}
                                        />
                                    )}
                                    renderLabel={labelProps => (
                                        <Label {...labelProps} hidden>
                                            New password
                                        </Label>
                                    )}
                                />
                            </Setting>
                            <Setting
                                heading="Confirm New Password"
                                description="Confirm your new password."
                            >
                                <InputGroup
                                    className="mt-5"
                                    id="confirm-new-password"
                                    hasError={showPasswordMatchError}
                                    helpText={
                                        showPasswordMatchError
                                            ? "Your passwords don't match"
                                            : ''
                                    }
                                    renderInput={inputProps => (
                                        <Input
                                            {...inputProps}
                                            value={newPasswordConfirm}
                                            type="password"
                                            autoComplete="new-password"
                                            onChange={e => {
                                                setNewPasswordConfirm(
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                    )}
                                    renderLabel={labelProps => (
                                        <Label {...labelProps} hidden>
                                            Confirm new password
                                        </Label>
                                    )}
                                />
                            </Setting>
                        </div>
                        <div className="flex w-full justify-end">
                            <PrimaryButton
                                className="mt-6"
                                type="submit"
                                disabled={disableButton}
                            >
                                Change password
                            </PrimaryButton>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    );
};
