import { ReactElement } from 'react';
import { useRouter } from 'next/router';

import { TRequireLoginProps } from './require-login.types';
import { useUser } from '../../../contexts';

export const RequireLogin = ({
    children,
}: TRequireLoginProps): ReactElement | null => {
    const router = useRouter();
    const { auth } = useUser();

    if (typeof window === 'undefined') {
        return null;
    }

    const now = Math.floor(Date.now() / 1000);
    const hasValidAuth = auth.header && auth.expires > now;

    if (!hasValidAuth) {
        const { pathname, search } = window.location;
        router.push({
            pathname: '/login',
            query: { from: `${pathname}${search}` },
        });
        return null;
    }

    return <>{children}</>;
};
