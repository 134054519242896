import { ReactElement } from 'react';
import clsx from 'clsx';

import { THeaderProps } from './table-header.types';

export const TableHeader = ({
    hidden,
    title,
    canSort,
    isSorted,
    isSortedDesc,
}: THeaderProps): ReactElement => (
    <div
        className={clsx(
            'flex items-center px-6 py-3',
            !canSort && 'cursor-auto',
            !hidden &&
                'text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
        )}
    >
        <span
            className={clsx(
                'select-none',
                hidden && 'hidden',
                // The sr-only class breaks horizontal scrolling on small displays in Safari. Seems to be something to do with the position: absolute.
                // hidden && 'sr-only',
            )}
        >
            {title}
        </span>
        <svg
            className={clsx(
                'h-4 w-auto',
                (!canSort || !isSorted) && 'opacity-0',
            )}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            {isSortedDesc ? (
                <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            ) : (
                <path
                    fillRule="evenodd"
                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                    clipRule="evenodd"
                />
            )}
        </svg>
    </div>
);
