import { useState, PropsWithChildren, ReactElement } from 'react';
import * as Sentry from '@sentry/nextjs';

import { useLocalStorage } from '../../hooks';
import { TServiceProvider } from '../../types';
import { initialAuth, UserContext } from './user.context';
import { TSettableAuth, TUserAuth } from './user.types';
import { logOut as apiLogOut, refreshToken } from '../../api';
import { isErrorResponse } from '../../utils';

const refreshSecondsBeforeExpires = 300;

export function UserProvider({
    children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
    const [auth, internalSetAuth] = useState<TUserAuth>(initialAuth);
    const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout>();
    const [serviceProviders, setServiceProviders] = useState<
        TServiceProvider[]
    >([]);
    const [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        localStorageAuth,
        setLocalStorageAuth,
    ] = useLocalStorage<TUserAuth | null>('ap-auth', null);
    const [rememberMe] = useLocalStorage<boolean>('ap-remember-me', false);

    const setAuth = (auth: TSettableAuth) => {
        const completeAuth: TUserAuth = {
            ...auth,
            header: `${auth.tokenType} ${auth.accessToken}`,
        };

        if (refreshTimeout) {
            clearTimeout(refreshTimeout);
        }

        const now = Math.floor(Date.now() / 1000);
        const timeoutMs =
            (auth.expires - now - refreshSecondsBeforeExpires) * 1000;

        const newTimeout = setTimeout(async () => {
            try {
                const refreshResponse = await refreshToken();

                if (isErrorResponse(refreshResponse)) {
                    return;
                }

                const { data } = refreshResponse;

                const auth = setAuth({
                    tokenType: data.token_type,
                    accessToken: data.access_token,
                    expires: data.expires,
                    expiresIn: data.expires_in,
                });
                setLocalStorageAuth(rememberMe ? auth : null);
            } catch (err) {
                console.error('Error refreshing token: ', err);
                Sentry.captureException(err);
            }
        }, timeoutMs);

        internalSetAuth(completeAuth);
        setRefreshTimeout(newTimeout);

        return completeAuth;
    };

    const logOut = () => {
        apiLogOut(auth.header);
        setLocalStorageAuth(null);
        internalSetAuth(initialAuth);
    };

    return (
        <UserContext.Provider
            value={{
                auth,
                setAuth,
                serviceProviders,
                setServiceProviders,
                logOut,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}
