import { ReactElement } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { TLayoutProps } from './layout.types';
import { Loading, NavBar, TNavLink } from '../..';
import { useUser } from '../../../contexts';

export const Layout = ({
    children,
    isLoading,
    pageTitle = 'ApaloSIM',
    heading,
}: TLayoutProps): ReactElement => {
    const router = useRouter();
    const { spid } = router.query;
    const { logOut } = useUser();

    const links: TNavLink[] = [
        {
            name: 'SIMs',
            href: `/${spid}/sims/allocated`,
            pathname: '/[spid]/sims/allocated',
        },
        {
            name: 'Activate SIM',
            href: `/${spid}/sims/available`,
            pathname: '/[spid]/sims/available',
        },
    ];

    const userLinks = [
        {
            name: 'Change password',
            href: `/${spid}/change-password`,
        },
        {
            name: 'Sign out',
            onClick: () => {
                logOut();
            },
        },
    ];

    return (
        <>
            <Head>
                <title>{pageTitle}</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <NavBar
                links={links}
                userLinks={userLinks}
                userFullName="Rebecca Nicholas"
                userEmail="rebecca.nicholas@example.com"
                userImageSrc="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
            />
            <header className="relative py-10 -mt-36">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-white">{heading}</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl mx-auto pb-12 px-0 sm:px-6 lg:px-8 grid grid-cols-1 gap-4">
                    {isLoading ? <Loading /> : children}
                </div>
            </main>
        </>
    );
};
