import { ReactElement } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { InformationCircleIcon } from '@heroicons/react/outline';

export const UnsupportedSim = (): ReactElement => {
    const router = useRouter();
    const { spid } = router.query;

    return (
        <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6 flex flex-col items-start">
                <div className="sm:mb-2 flex flex-row items-center">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        <InformationCircleIcon
                            className="h-6 w-6 text-primary-600"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-1 text-lg leading-6 font-medium">
                        This SIM can&apos;t be managed here
                    </div>
                </div>
                <p className="px-11 pt-2">
                    <Link href={`/${spid}/sims/allocated`}>
                        <a className="text-primary-600 font-medium">
                            View your SIMs
                        </a>
                    </Link>
                    {' or '}
                    <Link href={`/${spid}/sims/available`}>
                        <a className="text-primary-600 font-medium">
                            Activate a SIM
                        </a>
                    </Link>
                </p>
            </div>
        </div>
    );
};
